<section class="call-to-action" [style.background-image]="'url(assets/img/own/Footer.svg)'">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-7 col-md-8">
                <div class="section-title white-color">
                    <span class="title-tag">CALL TO ACTION</span>
                    <h2 class="title">
                        Call Us To Explore More
                    </h2>
                </div>
            </div>
            <div class="col-auto">
                <a routerLink="/contact" class="main-btn main-btn-3 rounded-btn mt-md-gap-30"> <i class="fal fa-comments"></i> Contact Us</a>
            </div>
        </div>
    </div>
</section>